import { IBfcConfiguration } from "@bfl/components/configuration";
import { Environment } from "../contact/models/environment.interface";
import { LOCAL_ENV_PORTAL_SERVICES } from "./environments/portal-services/local";
import { T_ENV_PORTAL_SERVICES } from "./environments/portal-services/t";
import { Q_ENV_PORTAL_SERVICES } from "./environments/portal-services/q";
import { P_ENV_PORTAL_SERVICES } from "./environments/portal-services/p";
import { GENERAL_ENV } from "./environments/bkw/general";
import { LOCAL_ENV } from "./environments/bkw/local";
import { T_ENV } from "./environments/bkw/t";
import { Q_ENV } from "./environments/bkw/q";
import { P_ENV } from "./environments/bkw/p";
import { GENERAL_ENV_PORTAL_SERVICES } from "./environments/portal-services/general";

export const configuration: IBfcConfiguration[] = [
  {
    theme: "bkw",
    general: GENERAL_ENV,
    local: LOCAL_ENV,
    t: T_ENV,
    q: Q_ENV,
    p: P_ENV,
  },
  {
    theme: "portal-services",
    general: GENERAL_ENV_PORTAL_SERVICES,
    local: LOCAL_ENV_PORTAL_SERVICES,
    t: T_ENV_PORTAL_SERVICES,
    q: Q_ENV_PORTAL_SERVICES,
    p: P_ENV_PORTAL_SERVICES,
  },
];

export interface AppConfiguration {
  opContactFormApiUrl?: string;
  futureCrmFormIntegratorApiUrl?: string;
  originTextFutureCrm?: string;
  opSelfServiceApiUrl?: string;
  googleTagManagerContainerId?: string;
  staticServerUrl?: string;
  contactEnvironments?: Environment[];
  backUrl?: string;
  notisOrganisationName?: string;
  companyDisplayName?: string;
  noFeedback?: boolean;
  privacyUrl?: { "de": string, "fr": string, "it"?: string, "en"?: string };
  hCaptchaSiteKey?: string;
}
