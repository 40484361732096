export const EN_TRANSLATIONS = {
  "CONTACT": {
    "TITLE": "Contact form",
    "ENVIRONMENT": {
      "ACCOUNT": {
        "TITLE": "Customer Centre",
        "REASON": {
          "GENERAL": "General request",
          "LOGIN": "Login / Registration",
          "ACCOUNT": "My account",
          "BUSINESS": "Services for business customers & partners",
        },
      },
      "CUSTOMER_SERVICE": {
        "TITLE": "Customer Centre",
        "REASON": {
          "GENERAL": "General request",
          "LOGIN": "Login / Registration",
          "ACCOUNT": "My account",
        },
      },
      "MYAEK": {
        "TITLE": "myAEK für Private & Gewerbe",
        "REASON": {
          "EFFICIENCY": "Stromeffizienz",
          "INFEED": "Stromeinspeisung",
          "GENERAL": "Rund um myAEK",
          "INVOICE": "Rund um die Stromrechnung",
          "PRODUCTS_PRICES": "Produkte / Preise",
        },
      },
      "MYONYX": {
        "TITLE": "myonyx für Private & Gewerbe",
        "REASON": {
          "EFFICIENCY": "Stromeffizienz",
          "INFEED": "Stromeinspeisung",
          "GENERAL": "Rund um myonyx",
          "INVOICE": "Rund um die Stromrechnung",
          "PRODUCTS_PRICES": "Produkte / Preise",
        },
      },
      "EICHFRIST": {
        "TITLE": "Lot Management",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "INSTALLER_SERVICES": {
        "TITLE": "Installateur Services",
        "REASON": { 
          "DATE_INQUIRY_MONTAGE": "Terminanfrage Zähler-apparate Montage",
          "DATE_INQUIRY_GRID_CONNECTION": "Terminanfrage Netzanschluss",
          "FAULT_REPORT_TRE": "Störungsmeldungen TRE, Zähler",
          "ORDER_INSTRUMENT_TRANSFORMER": "Bestellung Messwandler (Wie, Wann...)",
          "TEMPORARY_GRID_CONNECTION_BZK": "Temporärer Netzanschluss BZK",
          "NETWORK_QUALITY": "Netzqualität",
          "PERIODICAL_ELECTRICAL_INSTALLATION_CONTROL": "Periodische Elektroinstallationskontrolle",
          "OTHER": "Andere Themen",
        },
      },
      "M2C_BILLS": {
        "TITLE": "Meter2Cash Rechnungen",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "MDL": {
        "TITLE": "Messdienstleistungen",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "MYBKW": {
        "TITLE": "myBKW für Private & Gewerbe",
        "REASON": {
          "CUSTOMER_CENTER": "Rund um das Online-Kundencenter myBKW",
          "GENERAL": "Allgemeine Anfrage",
          "PRODUCTS_PRICES": "Produkte/Preise",
          "INVOICE": "Rund um die Rechnung",
          "POWER_SUPPLY": "Stromeinspeisung",
          "ACCOUNT_MANAGEMENT": "Kontoverwaltung",
          "REGISTRATION": "Registration",
          "POWER_EFFICIENCY": "Stromeffizienz",
        },
      },
      "SMC": {
        "TITLE": "Smart Meter Connect",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "SMSK": {
        "TITLE": "Smart Meter Starter Kit",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "VISUAL_EDM": {
        "TITLE": "Visualisierung EDM",
        "REASON": {
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "PLANTSERVICES": {
        "TITLE": "Nichtverfügbarkeiten von Kraftwerken",
        "REASON": {
          "MALFUNCTION": "Nichtverfügbarkeit kann nicht erfasst werden",
          "WRONG_DATA": "Stammdaten fehlerhaft",
          "PLANT_MISSING": "Anlage fehlt in der Auswahl",
          "POWER_AMOUNT": "Installierte Leistung nicht korrekt",
          "IMPROVEMENT": "Verbesserungsvorschlag",
        },
      },
      "DAVE_VISUALIZATION": {
        "TITLE": "Visualisierung Energie",
        "REASON": {
          "MISSING_METERPOINT": "Messpunkt fehlt in der Auswahl",
          "DATA_MISSING": "Werte sind nicht vorhanden",
          "DATA_WRONG": "Stammdaten fehlerhaft ",
          "ERROR": "Fehlermeldung ",
          "DATA_OUTDATED": "Werte sind nicht aktuell ",
          "ERROR_DATASHEET": "Datenblatt wird nicht korrekt angezeigt",
          "ERROR_DOWNLOAD": "Download funktioniert nicht",
          "ERROR_PDF": "Pdf kann nicht erstellt werden",
          "OTHER": "Anderer Grund",
        },
      },
      "GENERAL": {
        "TITLE": "Allgemeine BKW Services",
        "REASON": {
          "SHOP": "Warenkorb / Shop",
          "HOME_ENERGY": "Home Energy",
          "OUTAGE": "Stromausfall finden",
        },
      },
      "ENERGIEBILANZ": {
        "TITLE": "Energiebilanz",
        "REASON": {
          "ORDER": "Zugang bestellen",
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
          "WRONG_DATA": "Die angezeigten Daten sind nicht korrekt",
          "GENERAL": "Allgemeine Frage",
        },
      },
      "GRIDCONNECTION": {
        "TITLE": "Netzanschluss",
        "REASON": {
          "GENERAL": "Allgemeine Anfrage",
          "MALFUNCTION": "Die Anwendung funktioniert nicht richtig",
          "INSTRUCTION": "Ich benötige Hilfe bei der Bedienung",
        },
      },
      "EVU_MGMTSERVICES": {
        "TITLE": "Management Services",
        "REASON": {
          "APPOINTMENT": "Beratungstermin",
        },
      },
      "M2C_SHOP": {
        "TITLE": "M2C Shop",
        "REASON": {
          "QUESTION": "Frage an Geräteverkauf",
        },
      },
      "OSTRAL": {
        "TITLE": "Ostral Request",
        "REASON": {
          "GENERAL": "General request",
        },
      },
      "MDV": {
        "TITLE": "Measurement data visualization",
        "REASON": {
          "GENERAL": "General request",
        },
      },
      "PS_CC_MYBKW": {
        "TITLE": "BKW Customer Centre myBKW",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_BKW_BUSINESS": {
        "TITLE": "BKW Customer Centre BKW Business",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HINNI": {
        "TITLE": "Hinni Customer Portal",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_TID": {
        "TITLE": "TID Customer Centre",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HUBO": {
        "TITLE": "Hubo Customer Centre",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_HOME_ENERGY": {
        "TITLE": "Home Energy App",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_OTHER": {
        "TITLE": "Other",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
    },
    "REASON": "Reason for contact",
    "CUSTOMER_MESSAGE": "Your message",
    "REASON_TITLE": "Your request",
    "APPOINTMENT": {
      "TITLE": "Enter your desired date",
      "URGENT": {
        "CHECKBOX": "Urgent processing",
        "HINT": "Only select if processing is to take place within 24 hours.",
      },
      "HINT": "You will receive final confirmation of your appointment request by email. We will contact you if the appointment cannot be kept.",
      "DATUM": "Date",
      "TIME": "Time",
      "CANCEL": "Cancel",
      "SUBMIT": "Apply",
    },
    "PERSONAL_DATA": "Your contact information",
    "SALUTATION": "Title",
    "SALUTATIONS": {
      "MALE": "Mr",
      "FEMALE": "Ms",
    },
    "FIRST_NAME": "First name",
    "LAST_NAME": "Last name",
    "STREET": "Street",
    "HOUSE_NO": "No.",
    "ZIP": "Postcode",
    "CITY": "Town/city",
    "EMAIL": "Email",
    "PHONE": "Telephone number",
    "SAP_ISU_ACCOUNT_ID": "Customer number",
    "REQUIRED_FIELD": "This field is required",
    "INVALID_PHONE": "Invalid telephone number",
    "INVALID_ZIP": "Invalid postcode",
    "INVALID_EMAIL": "Please enter a valid email address",
    "SUBMIT": "Send",
    "CANCEL": "Cancel",
    "BACK": "Back",
    "BACK_TO": "Back to {{site}}",
    "PRIVACY": "I accept the <a class=\"text-underline\" href=\"{{href}}\" target=\"_blank\">{{company}} privacy policy</a>.",
    "SECONDARY_CONTENT": {
      "TITLE": "We are happy to help if you have any questions!",
      "PHONE": "Telephone",
    },
    "PARENT_TITLE": "Customer centre",
    "PARENT_URL": "https://account.bkw.ch",
    "PARENT_SERVICES": "Services",
    "SUCCESS": "Contact request sent successfully",
    "ERROR": "Contact request could not be sent",
    "SUCCESS_TITLE": "Thank you for your contact request",
    "SUCCESS_TEXT": "We will process your request as quickly as possible and look forward to helping you.",
  },
};
