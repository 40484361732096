export const IT_TRANSLATIONS = {
  "CONTACT": {
    "TITLE": "Modulo di contatto",
    "ENVIRONMENT": {
      "ACCOUNT": {
        "TITLE": "Centro clienti",
        "REASON": {
          "GENERAL": "Richiesta generale",
          "LOGIN": "Login / Registrazione",
          "ACCOUNT": "Il mio account",
          "BUSINESS": "Servizi per clienti aziendali e partner",
        },
      },
      "CUSTOMER_SERVICE": {
        "TITLE": "Centro clienti",
        "REASON": {
          "GENERAL": "Richiesta generale",
          "LOGIN": "Login / Registrazione",
          "ACCOUNT": "Il mio account",
        },
      },
      "MYAEK": {
        "TITLE": "myAEK per privati e attività commerciali",
        "REASON": {
          "EFFICIENCY": "Efficienza elettrica",
          "INFEED": "Immissione di elettricità",
          "GENERAL": "Info su myAEK",
          "INVOICE": "Info sulla bolletta della corrente",
          "PRODUCTS_PRICES": "Prodotti / Prezzi",
        },
      },
      "MYONYX": {
        "TITLE": "myonyx per privati e attività commerciali",
        "REASON": {
          "EFFICIENCY": "Efficienza elettrica",
          "INFEED": "Immissione di elettricità",
          "GENERAL": "Info su myonyx",
          "INVOICE": "Info sulla bolletta della corrente",
          "PRODUCTS_PRICES": "Prodotti / Prezzi",
        },
      },
      "EICHFRIST": {
        "TITLE": "Gestione dei lotti",
        "REASON": {
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "WRONG_DATA": "I dati visualizzati non sono corretti",
          "GENERAL": "Domanda generale",
        },
      },
      "INSTALLER_SERVICES": {
        "TITLE": "Servizi installatore",
        "REASON": {
          "DATE_INQUIRY_MONTAGE": "Richiesta di appuntamento montaggio impianto contatore",
          "DATE_INQUIRY_GRID_CONNECTION": "Richiesta di appuntamento allacciamento alla rete",
          "FAULT_REPORT_TRE": "Segnalazioni guasti ricevitore TRE, contatore",
          "ORDER_INSTRUMENT_TRANSFORMER": "Ordine trasformatore di misura (come, quando...)",
          "TEMPORARY_GRID_CONNECTION_BZK": "Allacciamento alla rete temporaneo con contatore da cantiere BZK",
          "NETWORK_QUALITY": "Qualità della rete",
          "PERIODICAL_ELECTRICAL_INSTALLATION_CONTROL": "Controllo periodico dell’impianto elettrico",
          "OTHER": "Altri argomenti",
        },
      },
      "M2C_BILLS": {
        "TITLE": "Fatture Meter2Cash",
        "REASON": {
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "WRONG_DATA": "I dati visualizzati non sono corretti",
          "GENERAL": "Domanda generale",
        },
      },
      "MDL": {
        "TITLE": "Servizi di misura",
        "REASON": {
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "WRONG_DATA": "I dati visualizzati non sono corretti",
          "GENERAL": "Domanda generale",
        },
      },
      "MYBKW": {
        "TITLE": "myBKW per privati e attività commerciali",
        "REASON": {
          "CUSTOMER_CENTER": "Info su Centro clienti online myBKW",
          "GENERAL": "Richiesta generale",
          "PRODUCTS_PRICES": "Prodotti / Prezzi",
          "INVOICE": "Info sulla fattura",
          "POWER_SUPPLY": "Immissione di elettricità",
          "ACCOUNT_MANAGEMENT": "Gestione account",
          "REGISTRATION": "Registrazione",
          "POWER_EFFICIENCY": "Efficienza elettrica",
        },
      },
      "SMC": {
        "TITLE": "Smart Meter Connect",
        "REASON": {
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "GENERAL": "Domanda generale",
        },
      },
      "SMSK": {
        "TITLE": "Smart Meter Starter Kit",
        "REASON": {
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "WRONG_DATA": "I dati visualizzati non sono corretti",
          "GENERAL": "Domanda generale",
        },
      },
      "VISUAL_EDM": {
        "TITLE": "Visualizzazione EDM",
        "REASON": {
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "WRONG_DATA": "I dati visualizzati non sono corretti",
          "GENERAL": "Domanda generale",
        },
      },
      "PLANTSERVICES": {
        "TITLE": "Indisponibilità di centrali elettriche",
        "REASON": {
          "MALFUNCTION": "L’indisponibilità non può essere registrata",
          "WRONG_DATA": "Dati fondamentali non corretti",
          "PLANT_MISSING": "Impianto non presente nella selezione",
          "POWER_AMOUNT": "Potenza installata non corretta",
          "IMPROVEMENT": "Proposte di miglioramento",
        },
      },
      "DAVE_VISUALIZATION": {
        "TITLE": "Visualizzazione energia",
        "REASON": {
          "MISSING_METERPOINT": "Punto di misurazione non presente nella selezione",
          "DATA_MISSING": "I valori non sono presenti",
          "DATA_WRONG": "Dati fondamentali non corretti",
          "ERROR": "Messaggio di errore",
          "DATA_OUTDATED": "I valori non sono aggiornati",
          "ERROR_DATASHEET": "La scheda non viene visualizzata correttamente",
          "ERROR_DOWNLOAD": "Il download non funziona",
          "ERROR_PDF": "Il pdf non può essere creato",
          "OTHER": "Altro motivo",
        },
      },
      "GENERAL": {
        "TITLE": "Servizi BKW generali",
        "REASON": {
          "SHOP": "Carrello / Shop",
          "HOME_ENERGY": "Home Energy",
          "OUTAGE": "Trovare un’interruzione di corrente",
        },
      },
      "ENERGIEBILANZ": {
        "TITLE": "Bilancio energetico",
        "REASON": {
          "ORDER": "Ordinare accesso",
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
          "WRONG_DATA": "I dati visualizzati non sono corretti",
          "GENERAL": "Domanda generale",
        },
      },
      "GRIDCONNECTION": {
        "TITLE": "Collegamento rete",
        "REASON": {
          "GENERAL": "Richiesta generale",
          "MALFUNCTION": "L’applicazione non funziona correttamente",
          "INSTRUCTION": "Ho bisogno di aiuto per l’uso",
        },
      },
      "EVU_MGMTSERVICES": {
        "TITLE": "Management Services",
        "REASON": {
          "APPOINTMENT": "Appuntamento di consulenza",
        },
      },
      "M2C_SHOP": {
        "TITLE": "M2C Shop",
        "REASON": {
          "QUESTION": "Domanda sulla vendita di apparecchi",
        },
      },
      "OSTRAL": {
        "TITLE": "Consultazione ostrale",
        "REASON": {
          "GENERAL": "Richiesta generale",
        },
      },
      "MDV": {
        "TITLE": "Visualizzazione dei dati di misura",
        "REASON": {
          "GENERAL": "Richiesta generale",
        },
      },
      "PS_CC_MYBKW": {
        "TITLE": "Centro clienti myBKW",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_BKW_BUSINESS": {
        "TITLE": "Centro clienti BKW Business",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HINNI": {
        "TITLE": "Portale clienti Hinni",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_TID": {
        "TITLE": "Centro clienti TID",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_HUBO": {
        "TITLE": "Centro clienti Hubo",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_HOME_ENERGY": {
        "TITLE": "Home Energy App",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
      "PS_CC_OTHER": {
        "TITLE": "Altro",
        "REASON": {
          "LOGIN": "Login / Portal Services",
        },
      },
    },
    "REASON": "Motivo del contatto",
    "CUSTOMER_MESSAGE": "Il Suo messaggio",
    "REASON_TITLE": "La Sua richiesta",
    "APPOINTMENT": {
      "TITLE": "Inserisca la data desiderata per un appuntamento",
      "URGENT": {
        "CHECKBOX": "Elaborazione urgente",
        "HINT": "Da selezionare solo se l’elaborazione deve avvenire entro 24 ore.",
      },
      "HINT": "Riceverà la conferma definitiva della richiesta di appuntamento via e-mail. Qualora non fosse possibile confermare l’appuntamento, provvederemo a contattarla.",
      "DATUM": "Data",
      "TIME": "Ora",
      "CANCEL": "Annulla",
      "SUBMIT": "Applica",
    },
    "PERSONAL_DATA": "I Suoi dati di contatto",
    "SALUTATION": "Appellativo",
    "SALUTATIONS": {
      "MALE": "Signor",
      "FEMALE": "Signora",
    },
    "FIRST_NAME": "Nome",
    "LAST_NAME": "Cognome",
    "STREET": "Via",
    "HOUSE_NO": "N.",
    "ZIP": "NPA",
    "CITY": "Luogo",
    "EMAIL": "E-mail",
    "PHONE": "Numero telefonico",
    "SAP_ISU_ACCOUNT_ID": "Numero cliente",
    "REQUIRED_FIELD": "Questo è un campo obbligatorio",
    "INVALID_PHONE": "Numero telefonico non valido",
    "INVALID_ZIP": "Numero postale d’avviamento non valido",
    "INVALID_EMAIL": "Si prega di inserire un indirizzo e-mail valido",
    "SUBMIT": "Invia",
    "CANCEL": "Annulla",
    "BACK": "Indietro",
    "BACK_TO": "Torna a {{site}}",
    "PRIVACY": "Dichiaro di accettare la <a class=\"text-underline\" href=\"{{href}}\" target=\"_blank\">dichiarazione sulla protezione dei dati</a> di {{company}}.",
    "SECONDARY_CONTENT": { 
      "TITLE": "In caso di domande siamo a Sua completa disposizione",
      "PHONE": "Telefono",
    },
    "PARENT_TITLE": "Centro clienti",
    "PARENT_URL": "https://account.bkw.ch",
    "PARENT_SERVICES": "Servizi",
    "SUCCESS": "Richiesta di contatto inviata correttamente",
    "ERROR": "Non è stato possibile inviare la richiesta di contatto",
    "SUCCESS_TITLE": "Grazie per la Sua richiesta di contatto",
    "SUCCESS_TEXT": "Elaboreremo la Sua richiesta il prima possibile. Siamo lieti di supportarla",
  },
};
